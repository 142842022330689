import React, {useMemo} from 'react';
import ModalImage from "react-modal-image";
import PropTypes from "prop-types";
import {toCurrencyFormat, isVariantDefault} from "../utility/currency";
import {addAlert} from "../utility/alert";
import {doFetch} from "../utility/fetch";
import {addProductToChart} from "../utility/api";

function Products(props) {
    /**
     * Uso la concatenazione degli id come cache
     * @type {*|string}
     */
    const productsIdConcat = props.products ? props.products.reduce((acc, actual) => {
        return acc + actual.id;
    }, '') : 'lol';

    const memo = useMemo(() => {
        //console.log('memo calcolato');
        return "ciao " + productsIdConcat;
    }, [productsIdConcat]);

    const addToChart = (product, productVariant) => {
        const addProductRequest = addProductToChart(product, productVariant);

        addProductRequest.then((response) => {
            props.onProductAdd(product);
            addAlert('Prodotto aggiunto dal carrello');
        });
    };

    const renderProducts = (productsReceived) => {
        if (!productsReceived) { return <h1>Loading...</h1>; }

        if (productsReceived.length === 0) { return <h1>Nessun prodotto trovato</h1>; }

        /**
         * variabile usata per raggruppare nelle categorie i prodotti
         */
        const categories = {};
        for (const productIndex in productsReceived) {
            const product = productsReceived[productIndex];

            if (!categories[product.classification.id]) {
                categories[product.classification.id] = {
                    classification: product.classification,
                    products: []
                }
            }

            categories[product.classification.id].products.push(product);
        }

        const categoriesNode = [];

        for (const catId in categories) {
            if (!categories.hasOwnProperty(catId)) { continue; }

            const category = categories[catId];
            const categoryNode = (
                <div key={catId}>
                    <blockquote className="blockquote">
                        <p className="mb-0">{category.classification.name}</p>
                        <footer className="blockquote-footer">{category.classification.description}</footer>
                    </blockquote>
                    {category.products.map((item) => {
                        const variants = [];
                        const noVariants = [];

                        if (item.productVariants) {
                            for (const variant of item.productVariants) {
                                const variantTitle = isVariantDefault(variant)
                                    ? null
                                    : <small className={'mr-2 variant-title'}>{variant.variant.title}</small>;


                              if (variantTitle == null) {

                                noVariants.push(<div key={variant.id}>
                                    <div className="text-right">
                                      <strong className="product-price no-variants mr-2">
                                       {toCurrencyFormat(variant.price)}
                                      </strong>
                                      <button className="btn btn-sm product-add" onClick={addToChart.bind(null, item, variant)}>
                                          <i className="fa fa-plus" aria-hidden="true" />
                                      </button>
                                    </div>
                                </div>);

                              } else {

                                variants.push(<div className={'mb-1'} key={variant.id}>
                                    <strong className="product-price float-left">
                                        {variantTitle}
                                    </strong>
                                    <div className="text-right">
                                      <strong className="product-price mr-2">
                                       {toCurrencyFormat(variant.price)}
                                      </strong>
                                      <button className="btn btn-sm product-add" onClick={addToChart.bind(null, item, variant)}>
                                          <i className="fa fa-plus" aria-hidden="true" />
                                      </button>
                                    </div>
                                </div>);

                              }
                            }
                        }

                        return (<div key={item.id}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <h6 className="mb-2 product-name">{item.title}
                                      <span className="float-right">
                                        {noVariants}
                                      </span>
                                    </h6>
                                    <p className="text-muted product-description" dangerouslySetInnerHTML={{__html: item.description}} />
                                    {variants}
                                    {item.photo ? <ModalImage className="img-shop" hideDownload="true" hideZoom="true" small={item.photo_url} large={item.photo_url} alt={item.title} />  : null}
                                </div>
                            </div>
                            <hr className="mb-3" />
                        </div>);
                    })}
                </div>
            );

            categoriesNode.push(categoryNode);
        }

        return categoriesNode;
    };

    const productsNode = renderProducts(props.products);

    return (<div className="col-lg-6 mb-2 px-1">
            <div className="bg-light px-3 py-4 h-100">
                {productsNode}
            </div>
        </div>
    );
}

Products.propTypes = {
    onProductAdd: PropTypes.func,
    products: PropTypes.array,
};

export default Products;
