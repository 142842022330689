import {doFetch} from "./fetch";
import {addAlert} from "./alert";

/**
 * Aggiungo un prodotto che adesso è una variante di prodotto al carrello
 *
 * @param product
 * @param productVariant
 * @returns {Promise<any | never>}
 */
export function addProductToChart(product, productVariant) {
    return doFetch(`/api/cart/add/${product.id}/${productVariant.id}`)
        .then((response) => {
            if (!response.ok) {
                response.json().then(function(object) {
                    addAlert(object.error);
                });
                return;
            }
            return response.json();
        });
}

export function getCartInfo() {
    return doFetch(`/api/cart`)
        .then((response) => {
            return response.json();
        });
}