import React, {useMemo, useState} from 'react';
import PropTypes from "prop-types";

/**
 * @todo: controllare come mai viene renderizzato 2 volte
 * @todo: controllare il debounce delle categorie per evitare di cliccarci più volte in poco tempo
 * @param props
 * @returns {*}
 * @constructor
 */
function Categories(props) {
    const [chosenCategories, setChosenCategories] = useState([]);

    const toggleChosenCategory = (categoryId) => {
        let finalCategories;

        if (chosenCategories.indexOf(categoryId) >= 0) {
            finalCategories = chosenCategories.filter((item) => item !== categoryId);
        } else {
            finalCategories = [...chosenCategories, categoryId];
        }

        setChosenCategories(finalCategories);
        props.onCategoryChosen(finalCategories);
    };

    const toggleAllCategories = (event) => {
        const finalCategories = [];

        if (chosenCategories.length === props.categories.length) {
            setChosenCategories(finalCategories);
            props.onCategoryChosen(finalCategories);
            return;
        }

        for (const category of props.categories) {
            finalCategories.push(category.id);
        }

        setChosenCategories(finalCategories);
        props.onCategoryChosen(finalCategories);
    };

    const categoriesNode = props.categories.length > 0
        ? props.categories.map((item) => {
            return (
                <label className="container-check mb-3" key={item.id}>
                    {item.name} ({item.productsCount})
                    <input
                        type="checkbox"
                        value={item.id}
                        checked={chosenCategories.indexOf(item.id) >= 0}
                        onChange={() => toggleChosenCategory(item.id)} />
                    <span className="checkmark"/>
                </label>);
        })
        : (<h1>Loading...</h1>);

    const tuttiCount = useMemo(() => props.categories.reduce((acc, curr) => (acc + curr.productsCount), 0));

    return (
        <div className="col-lg-3 mb-2 px-1">
            <div className="bg-light p-3 h-100">
                <form id="product-form" action="">
                    <label className="container-check">Tutti ({tuttiCount})
                        <input
                            id="select-all"
                            type="checkbox"
                            checked={chosenCategories.length === props.categories.length}
                            onChange={toggleAllCategories}/>
                        <span className="checkmark"/>
                    </label>
                    <hr />
                    {categoriesNode}
                </form>
            </div>
        </div>
    );
}

Categories.propTypes = {
    onCategoryChosen: PropTypes.func,
    categories: PropTypes.array.isRequired,
};

export default Categories;
