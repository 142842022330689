import React from 'react';

function Client(props) {
    const clientNode = props.client
        ? (<div className="col-lg-12 mb-4 px-1">
            <div className="mobile-width">
             <img className="avatar mr-2" src={props.client.logo_url} alt="shop-name" />
              <span className="shop-name d-inline-block align-middle w-50 margin-description">
                <strong>{props.client.businessName}</strong><br />
                <small dangerouslySetInnerHTML={{__html: props.client.description}} />
              </span>
              <div className="float-right font-marker d-inline-block mt-3">
                 <img className="mr-2 float-left d-none d-md-block" src="https://www.italytohome.com/frontend/img/icona-marker.png" alt="marker" />
                 <p><strong className="mr-1">Indirizzo:</strong> {props.client.fullAddress}<br/>
                 <strong className="mr-1">Orari:</strong> {props.client.retailerInfo.onlyOpeningTime} - {props.client.retailerInfo.onlyClosingTime}<br/>
                 <strong className="mr-1">Tel:</strong> {props.client.phone}
                 </p>
                 <a className={"btn " + (props.client.detailText ? '' : 'd-none')}  href={"/more-details?retailer=" + props.client.id}>Scopri di più</a>
              </div>
            </div>
            {props.client.userInfo
            ?
              <div className="col-sm-12 col-12 p-0">
                <div className="mobile-width bg-light mt-5 p-3 mt-user-info-mobile custom-strong-color">
                  <h3 className="text-center">Informazioni utili</h3>
                  <span dangerouslySetInnerHTML={{__html: props.client.userInfo}} />
                  <style dangerouslySetInnerHTML={{__html: `
                    .custom-strong-color strong {
                      color: ` + props.client.colorInfo +`
                    }
                  `}} />
                </div>
              </div>
            : ''}
          </div>)
        : (<h1>Loading...</h1>);

    return (clientNode);
}

export default Client;
