import React, {useEffect, useState} from 'react';
import './App.css';
import Retailers from "./components/Retailers";
import {doFetch} from "./utility/fetch";

function RetailerPage() {
    const [retailers, setRetailers] = useState(null);

    useEffect(() => {
        const query = window.retailerZona ? `?zona=${window.retailerZona}` : '';

        const retailersFetch = doFetch(`/api/retailers${query}`)
            .then((response) => {
                return response.json();
            });

        Promise.all([retailersFetch]).then(([retailers]) => {
            setRetailers(retailers);
        });
    }, []);

    return (
        <div className="container">
            <div className="row">
                <Retailers retailers={retailers} />
            </div>
        </div>
    );
}

export default RetailerPage;
