/**
 *
 * @param resource
 * @param initOptions
 * @returns {Promise<Response>}
 */
export function doFetch(resource, initOptions) {
    initOptions = {...initOptions, credentials: 'include'};

    if (resource.startsWith("/")) {
        resource = `${window.basepath}${resource}`;
    }

    return fetch(resource, initOptions);
}