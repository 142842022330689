/**
 *
 * @param number
 * @returns {*}
 */
export function toCurrencyFormat(number) {
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(number/100)
}

export function isVariantDefault(productVariant) {
    return productVariant.variant.title.startsWith("_default_");
}