import React from 'react';
import PropTypes from "prop-types";

function Retailers(props) {
    const retailerSelected = (retailer) => {
        window.retailerSelected(retailer);
    };

    let productsNode;

    if (props.retailers) {
        productsNode = props.retailers.length > 0
            ? props.retailers.map((item) => (<div key={item.id}>
                <div className="row">
                    <div className="col-lg-7">
                        <h6 className="mb-3 product-name">{item.businessName}</h6>
                        <p className="text-muted product-description" dangerouslySetInnerHTML={{__html: item.description}}/>
                    </div>
                    <div className="col-lg-5 text-right">
                        <strong className="product-price mx-3">
                            {item.city}
                        </strong>
                        <a className="btn" onClick={retailerSelected.bind(null, item)}>Seleziona</a>
                    </div>
                </div>
                <hr className="mb-5" />
            </div>))
            : (<h1>Nessun cliente trovato</h1>);
    } else {
        productsNode = <h1>Loading...</h1>;
    }

    return (<div className="col-lg-12 mb-2 px-1">
            <div className="bg-light px-3 py-4 h-100">
                {productsNode}
            </div>
        </div>
    );
}

Retailers.propTypes = {
    retailers: PropTypes.array.isRequired,
};

export default Retailers;
