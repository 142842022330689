import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import Categories from "./components/Categories";
import Client from "./components/Client";
import Products from "./components/Products";
import Cart from "./components/Cart";
import {doFetch} from "./utility/fetch";

function ShopPage() {
    const [client, setClient] = useState(null);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState(null);
    const [productToAdd, setProductToAdd] = useState({
        timestamp: (new Date()).getMilliseconds(),
        product: null
    });

    useEffect(() => {
        const queryStringObjPerProducts = {};

        if (window.retailer) {
            queryStringObjPerProducts['retailer'] = window.retailer;
        }

        const clientFetch = doFetch(`/api/retailers/${window.retailer}`)
            .then((response) => {
                return response.json();
            });
        const categoriesFetch = doFetch(`/api/classifications?${new URLSearchParams(Object.entries(queryStringObjPerProducts))}`)
            .then((response) => {
                return response.json();
            });
        const productsFetch = doFetch(`/api/products?${new URLSearchParams(Object.entries(queryStringObjPerProducts))}`)
            .then((response) => {
                return response.json();
            });

        Promise.all([clientFetch, categoriesFetch, productsFetch]).then(([client, categories, products]) => {
            console.log(client, categories, products); // [3, 1337, "foo"]
            setClient(client);
            setCategories(categories);
            setProducts(products);
        });
    }, []);

    const onCategoryChosen = (categoriesChosen) => {
        const queryStringObj = {};

        if (categoriesChosen.length > 0) {
            queryStringObj['categories'] = categoriesChosen;
        }

        if (window.retailer) {
            queryStringObj['retailer'] = window.retailer;
        }

        doFetch(`/api/products?${new URLSearchParams(Object.entries(queryStringObj))}`)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setProducts(response);
            });
    };

    const onProductAdd = (product) => {
        const timestamp = (new Date()).getMilliseconds();

        setProductToAdd({
            timestamp,
            product
        });
    };

    const cartNode = useMemo(() => {
        return <Cart productToAdd={productToAdd} client={client} />;
    }, [productToAdd, client]);

    const clientNode = useMemo(() => {
        return <Client client={client} />
    }, [client]);

    return (
        <div className="container">
            <div className="row">
                {clientNode}
            </div>
            <div className="row">
                <Categories categories={categories} onCategoryChosen={onCategoryChosen} />
                <Products products={products} onProductAdd={onProductAdd} />
                {cartNode}
            </div>
        </div>
    );
}

export default ShopPage;
