import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import RetailerPage from "./RetailerPage";
import ShopPage from "./ShopPage";

if (document.getElementById('shoproot')) {
    ReactDOM.render(
        <React.StrictMode>
            <ShopPage />
        </React.StrictMode>,
        document.getElementById('shoproot')
    );
}

if (document.getElementById('retailersroot')) {
    ReactDOM.render(
        <React.StrictMode>
            <RetailerPage  />
        </React.StrictMode>,
        document.getElementById('retailersroot')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
