import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {isVariantDefault, toCurrencyFormat} from "../utility/currency";
import {addAlert} from "../utility/alert";
import {doFetch} from "../utility/fetch";
import {addProductToChart, getCartInfo} from "../utility/api";

function Cart(props) {

    const [products, setProducts] = useState([]);
    const [cartInfo, setCartInfo] = useState({totalPrice: 0, productsPrice: 0, shipmentPrice: 0});
    const [coupon, setCoupon] = useState({discount: 0, userCode: '', checking: false});

    useEffect(() => {
        const cartRequest = getCartInfo();

        cartRequest.then((response) => {
            setProducts(response.products);
            setCartInfo(response.meta);
        })
    }, [props.productToAdd.timestamp]);

    const removeProduct = (product) => {
        doFetch(`/api/cart/remove/${product.product.id}/${product.id}`)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                doFetch(`/api/cart`)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        setProducts(response.products);
                        setCartInfo(response.meta);
                        addAlert('Prodotto rimosso dal carrello');
                    })
            });
    };

    const addToChart = (productVariant) => {
        const addProductRequest = addProductToChart(productVariant.product, productVariant);

        addProductRequest.then((response) => {
            const cartRequest = getCartInfo();

            cartRequest.then((response) => {
                setProducts(response.products);
                setCartInfo(response.meta);
            });

            addAlert('Prodotto aggiunto al carrello');
        });
    };

     const checkCoupon = (coupon) => {
        setCoupon({checking: true});

        if (coupon.userCode != '') {

         doFetch(`/api/cart/coupon/${coupon.userCode}/${props.client.id}`)
            .then((response) => {
                 return response.json();
             })
             .then((response) => {
               if (response.statusCode == 400) {
                 addAlert(response.error);
               } else {
                 setCoupon(response.data);
                 addAlert('Coupon validato correttamente');
               }
             });

        } else {
         setCoupon({checking: false});
         addAlert('Inserire il coupon da validare');
       }

    };

    const handleKeyPress = (e) => {
      if(e.charCode==13){
        e.preventDefault();
        document.getElementById('btn-coupon').click();
      }
    };

    const productsNode = products.map((product) => {
        const variantTitle = product.product.variant && !isVariantDefault(product.product)
            ? <small>{product.product.variant.title}</small>
            : '';
        return <li key={product.id} className="d-flex justify-content-between lh-condensed align-items-center mb-1">
            <div className="w-100">
                <a className="btn btn-sm product-less" onClick={removeProduct.bind(null, product.product)}>
                    <i className="fa fa-minus" aria-hidden="true"/>
                </a>
                <a className="btn btn-sm product-more" onClick={addToChart.bind(null, product.product)}>
                    <i className="fa fa-plus" aria-hidden="true"/>
                </a>
            </div>
            <div className="px-2 product-name">
                {product.product.product.title}<br />
                {variantTitle}
            </div>
            <strong className="product-price">
                <small>{product.quantity}x</small>
                {toCurrencyFormat(product.product.price)}
            </strong>
        </li>;
    });

    const minimumPurchase = props.client && props.client.retailerInfo && props.client.retailerInfo.minimumPurchase
        ? props.client.retailerInfo.minimumPurchase
        : 0;

    const contribution = props.client && props.client.retailerInfo && props.client.retailerInfo.hasContribution
        ? props.client.retailerInfo.contribution
        : 0;

    const canOrder = minimumPurchase <= cartInfo.totalPrice;

    const discountPrice = coupon.discount > 0 ? cartInfo.totalPrice - (coupon.discount * cartInfo.totalPrice / 100) : 0;

    return (
        <div id="cccart" className="col-lg-3 mb-2 shopping-cart px-1">
            <form className="bg-light p-3 h-100 ordina-adesso-form">
                <h6 className="d-flex justify-content-between align-items-center mb-3">Totale Ordine <span
                    className="total-price float-right">{discountPrice > 0 ? <div><del>{toCurrencyFormat(cartInfo.totalPrice)}</del> {toCurrencyFormat(discountPrice)}</div> : toCurrencyFormat(cartInfo.totalPrice > 0 ? cartInfo.totalPrice  + contribution : 0)}</span></h6>
                {/*<input type="text" className="w-75 input-coupon" onKeyPress={handleKeyPress} placeholder="Inserisci un coupon" onChange={(event) => setCoupon({userCode: event.target.value})} />
                <a
                  id='btn-coupon'
                  className={"btn btn-coupon d-inline ml-3" + (coupon.checking ? ' disable-coupon' : '')}
                  onClick={checkCoupon.bind(null, coupon)}
                  href="javascript:void(0);"
                  ><i class="fa fa-check" aria-hidden="true"></i></a>
                <hr /> */}
                <ul className="p-0 m-0 product-list">
                    {productsNode}
                </ul>
                <hr />
                <ul className="p-0 m-0 list-unstyled shop-info">
                    <li className="justify-content-between lh-condensed align-items-center mb-1">
                      <div className="w-100 d-inline-block">
                         Subtotale <span className="float-right">{toCurrencyFormat(discountPrice > 0 ? discountPrice : cartInfo.totalPrice)}</span>
                      </div>
                      <div className="w-100 d-inline-block">
                         San Valentino Game <span className="float-right">{toCurrencyFormat(contribution)}</span>
                      </div>
                    </li>
                </ul>
                <input type="submit" className="btn btn-block mt-4 font-weight-bold" name="ordina-adesso" value="Ordina Adesso" disabled={!canOrder}/>
                <small>Spesa minima: {toCurrencyFormat(minimumPurchase)}</small>
            </form>
        </div>
    );
}

Cart.propTypes = {
    productToAdd: PropTypes.object,
    client: PropTypes.object
};

export default Cart;
